@import "styles/styles.scss";
.FeatureCards__copy {
  position: relative;
  z-index: 1;
  transition: var(--text-transition);

  @include desktop-up {
    max-width: 450px;
  }

  p, a {
    @include bodyCopySmall;
    
    margin-bottom: 10px;

    @include desktop-up {
      @include bodyCopyRegular;
    }

    @include tablet-pro-only-portrait {
      @include bodyCopySmall;
    }
  
    @include tablet-only-landspace {
      @include bodyCopySmall;
    }
  }

  a {
    font-weight: 600;
  }
 
}


@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.svgIcon {
  background-color: var(--text-color);
  display: block;
  mask-repeat: no-repeat;
  mask-position: center;
  animation: fade-in 0.2s ease-in forwards;
  max-width: 100%;
  height: var(--svgIcon-height);
  width: 100%;
  margin-top: 20px;

  @include tablet-up {
    width: var(--svgIcon-width);
    height: var(--svgIcon-height);
  }

  @include desktopX1-up {
    margin-top: 40px;
  }
}

.FeatureCards__contentButton {
  background-color: transparent;
  border-color: var(--text-color);
  color: var(--text-color);
  transition: var(--text-transition), border-color 0.3s cubic-bezier(0.4, 0, 0.6, 1);
  margin: 40px 0 0;
}

.FeatureCards__copy.default {
  .FeatureCards__contentButton {
    color: $color-terciary;
    border-color: $color-terciary-extra-light;

    &:hover {
      color: $color-primary;
      border-color: $color-primary;
    }
  }
}