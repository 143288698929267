@import "styles/styles.scss";
.media__image_front {
  display: none;

  &.right {
    left: auto;
    right: -30px;
  }

  & > img,
  & > video {
    width: 100%;
  }

  @include tablet-up {
    display: block;
    left: -47%;
    width: 79%;
    position: absolute;
    bottom: 0;
    z-index: 2;
  }

  > :global(.grid__container) {
    max-width: 1440px;
  }
}

.media__image_back {
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  max-height: 90vw;
  z-index: 1;

  &.right {
    justify-content: flex-start;
  }

  &.multipleMedias {
    @include tablet-up {
      margin-bottom: 28%;
    }
  }

  & > img {
    width: 100%;
  }
}

.mediaWrapper {
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
}

.media__image {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;

  @include tablet-up {
    height: 100%;
  }
}

.media__video {
  video {
    display: block;
    height: auto;
    width: 100%;
  }
}

.media__iframe {
  width: 100%;
  height: 0;
  max-width: 100%;
  padding-bottom: 56.25%;
  margin: 0 auto;
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}
