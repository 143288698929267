@import "styles/styles.scss";
.lightbox__column {
  max-width: 100vw;
  margin: auto;
  flex: 0 1 auto;
  padding: 40px 30px;
  position: relative;
  width: 100%;
  z-index: 1;

  @include desktop-up {
    padding: 60px 70px 80px;
    width: 100%;
    max-width: 872px;
  }

  &:global(.compare-lengths) {
    background-color: $color-neutral-min;
    max-width: 375px;
    margin: auto;
    flex: 0 1 auto;
    padding: 40px 30px;
    position: relative;
    width: 100%;
    z-index: 1;

    @include desktop-up {
      max-width: 400px;
      padding: 40px 50px;
    }
  }
}

.lightbox__title {
  @include titleSmallX1(true);

  margin-bottom: 25px;
  color: $color-primary;
}

.lightbox__byline {
  margin-bottom: 20px;

  @include tablet-up {
    margin-bottom: 0;
  }
}

.lightboxContent {
  width: 100%;
}

.lightboxNavButton {
  padding: 16px 0;
}

.lightboxContent__nav {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 30px;
  justify-content: center;
  width: 100%;

  &:global(.size-chart) {
    flex-wrap: wrap;

    @include tablet-up {
      flex-wrap: nowrap;
      padding-top: 30px;
    }
  }

  &:global(.compare-lengths) {
    margin-left: auto;
    margin-right: auto;
  }

  .lightboxNavButton {
    width: 100%;

    @include tablet-up {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.carousel__dots {
  margin-top: 25px;
}
