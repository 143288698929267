@import "styles/styles.scss";
.carousel {
  width: 100%;
  overflow: hidden;
  position: relative;

  &.primary .buttons__container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  &.secondary .buttons__container {
    position: absolute;
    top: 0;
    right: 0;
  }

  &.secondary:hover .buttons__container {
    visibility: visible;
    opacity: 1;
  }
}

.carousel__slide {
  flex: 0 0 100%;
  min-width: 0;
  width: 100%;
}

.carousel__itemsContainer {
  position: relative;
  display: flex;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  &.fade.isReady {
    transform: none !important;

    .carousel__slide {
      transform: none !important;
      opacity: 0;
      transition: opacity 0.3s;

      &:not(:first-child) {
        position: absolute;
      }

      &.carousel__slide_isActive {
        opacity: 1;
      }
    }
  }
}

.ButtonsContainer {
  display: flex;
  position: absolute;
  top: 0;
  left: 100%;
 
  &__secondary {
    position: absolute;
    top: -10px;
    right: 0;
    left: inherit;

    @include tablet-up {
      top: -20px;
      right: -20px;
      width: auto;
    }
  }
}

.carouselButton {
  outline: 0;
  cursor: pointer;
  position: absolute;
  z-index: 1;

  &__primary {
    width: 70px;
    height: 100%;

    svg {
      width: 25px;

      path {
        transform: translateX(10px);
        stroke: $color-neutral-min;
        stroke-width: 3;
      }
    }

    @include desktop-up {
      width: 100px;
    }
  }

  &__secondary {
    justify-content: center;
    align-items: center;
    padding: 20px;
    display: flex;
    position: relative;

    svg {
      height: 20px;
      width: 10px;
    }

    @include desktop-up {
      display: flex;
    }
  }

  &:disabled {
    visibility: hidden;
    opacity: 0;
  }
}

.carouselButton_prev {
  left: 0;
}

.carouselButton_next {
  right: 0;

  svg {
    transform: rotate(180deg);
  }
}

.carousel__dots {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  gap: 18px;
  
  @extend %list-no-style;
}

.carousel__dot {
  background-color: $color-terciary-extra-light;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  transition: transform 0.5s;
  transform: scale(1);
}

.carousel__dot.selected {
  background-color: $color-primary;
  transform: scale(1.4);
}
