@import "styles/styles.scss";
.FeatureCards {
  background-color: $color-neutral-min;
  width: 100%;
  padding: 0 0 50px ;

  @include tablet-up {
    padding: 0 0 80px;
  }

  &:not(.FeatureCards_fullBleed) {
    .FeatureCards__wrapper {
      max-width: $max-width;
      margin: 0 auto;
    }
  }
}

.FeatureCards_withBackground {
  background-color: $color-neutral-light;
  padding: 50px 0;
  margin-bottom: 50px;
  
  @include tablet-up {
    padding: 80px 0;
  }

  @include desktop-up {
    margin-bottom: 80px;
  }
}

.FeatureCards_fullBleed {
  margin-bottom: 50px;

  @include desktop-up {
    margin-bottom: 80px;
  }

  padding: 0;

  @include tablet-up {
    padding: 0;
  }
}

.FeatureCards__wrapper {
  width: 100%;

  @include tablet-up {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @include desktop-up {
    width: 100%;
    padding: 0 40px;
  }

  @include desktopX1-up {
    padding: 0 80px;
  }

  @include desktopX2-up {
    padding: 0;
  }
}

.FeatureCards__sectionTitle {
  @include subtitleLargeX1(true);

  margin-bottom: 10px;
  position: relative;
  z-index: 1;
  color: var(--text-color);
  transition: var(--text-transition);
}