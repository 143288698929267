@import "styles/styles.scss";
.sizingItemHero {
  background: no-repeat top center;
  background-size: 375px auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 550px;
  max-width: 375px;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 375px;

  @include tablet-up {
    max-width: 100%;
    opacity: 1;
    transition: all 0.3s cubic-bezier(0, 0, 0.46, 1.06) 0.3s;
    pointer-events: none;
    width: 100%;
  }

  @include desktop-up {
    pointer-events: inherit;
  }

  &::after,
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  &::before {
    background: no-repeat top center;
    background-size: 375px auto;
    z-index: 2;

    @include tablet-up {
      background-image: var(--sizing-item-hero-image);
    }
  }

  &::after {
    height: 0;
    transition: all 0.6s ease;
    @include tablet-up {
      background-color: var(--sizing-item-hero-hover-background-color);
    }
  }

  @include desktop-up {
    transition: background-color 0.3s ease;
  }

  .sizingItemHero__content {
    align-items: center;
    top: calc(100% - 52px);
    display: flex;
    flex-direction: column;
    height: 200px;
    justify-content: flex-start;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 3;

    @include desktop-up {
      padding: 15px 0 20px;
      transition: all 0.3s cubic-bezier(0, 0, 0.46, 1.06) 0s;
    }
  }

  &:hover {
    .sizingItemHero__content {
      @include desktop-up {
        background-color: var(
          --sizing-item-hero-content-transition-background-color
        ) !important;
        transition: all 0.3s ease-in-out;
        padding: 40px 0;
        top: calc(100% - 200px);
      }
    }

    &::after {
      height: 100%;
      transition: all 0.3s ease;
    }
  }

  .sizingItemHero__title {
    @include subtitleLarge(true);

    color: $color-neutral-min;
  }

  .sizingItemHero__description {
    color: $color-neutral-min;
    margin: 15px 30px 0;
    max-width: 320px;
    text-align: center;

    @include desktop-up {
      margin: 15px 20px 0;
      max-width: 280px;
    }

    @media screen and (min-width: 500px) and (max-height: 1000px) {
      max-width: 320px;
    }
  }

  .sizingItemHero__mobileIcon {
    margin-top: auto;
    transform: rotate(45deg);
  }

  &.sizingItemHero_mobile {
    height: 100%;
    width: 100%;

    .sizingItemHero__content {
      height: 259px;
      padding: 44px 0 30px;
      top: calc(100% - 259px);
      opacity: 1;
      transition: all 0.3s cubic-bezier(0, 0, 0.46, 1.06) 0.3s;
    }
  }

  &.sizingItemHero_mobileActive {
    height: 570px;
    width: 100%;
    opacity: 1;
    transition: opacity 1s ease-in-out 0.3s;
    background: no-repeat top center;
    background-size: 375px auto;
    pointer-events: initial;

    .sizingItemHero__content {
      opacity: 1;
      transition: all 0.3s cubic-bezier(0, 0, 0.46, 1.06) 0.3s;
    }
  }
}
