@import "styles/styles.scss";
.sectionHeader {
  margin: 0 auto 30px;

  @include tablet-up {
    margin: 0 auto 40px;
  }

  &.sectionHeader_inline {
    @include tablet-up {
      display: flex;
      justify-content: flex-end;
      flex-flow: row-reverse wrap;
      align-items: baseline;
      margin-left: 0;
      padding-right: 200px;

      h2 {
        margin: 0 12px 0 0;
      }
    }
  }

  &.default {
    text-align: center;
  }

  &.left_aligned {
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 40px;

    &::after {
      bottom: 0;
      background-color: $color-secondary;
      content: "";
      display: block;
      height: 3px;
      position: absolute;
      left: 0;
      width: 60px;
    }

    h2 {
      @include titleSmallX1();

      @include desktop-up {
        @include titleMedium();
      }
    }
  }
}

.sectionHeader__byline {
  @include bodyCopySmall(true);

  color: $color-terciary-light;

  @include desktop-up {
    @include bodyCopyRegular;
  }
}
