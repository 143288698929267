@import "styles/styles.scss";
.productCardDetails {
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .productCardDetails__description {
    @include bodyCopySmall;

    min-height: 96px;
    margin-bottom: 10px;
    flex: 1;
  }

  .productCardDetails__title {
    margin-bottom: 2px;
    text-decoration: none;

    h3 {
      @include titleSmallX2;
    }
  }

  .productCardDetails__byline {
    @include bodyCopyRegular;

    margin-bottom: 15px;
    flex: 1;
  }

  .productCardDetails__link {
    display: inline-block;
    margin-top: 20px;
  }
}
