@import "styles/styles.scss";
.lightboxContent__item {
  img {
    display: block;
  }
}

.lightboxContent__subtitle,
.lightboxContent__description {
  @extend %center;
}

.lightboxContent__subtitle {
  color: $color-primary;
  margin-top: 30px;

  @include subtitleLarge(true);

  &:global(.compare-lengths) {
    max-width: 300px;
  }

  &:global(.size-chart) {
    @include subtitleLargeX2;
  }
}

.lightboxContent__description {
  margin-top: 10px;

  &:global(.compare-lengths) {
    max-width: 300px;
  }

  &:global(.size-chart) {
    margin-top: 13px;
    max-width: 320px;

    @include tablet-up {
      margin-bottom: 30px;
    }
  }
}

.tabItem__Icon {
  height: 175px;
  width: 210px;

  @include tablet-up {
    height: 196px;
    width: 235px;
  }

  &[name="thigh"] {
    @include tablet-up {
      height: 201px;
      width: 260px;
    }
  }

  &[name="knee"] {
    @include tablet-up {
      height: 169px;
      width: 270px;
    }
  }

  &[name="waist"] {
    @include tablet-up {
      height: 187px;
      width: 300px;
    }
  }

  &[name="leg"] {
    @include tablet-up {
      height: 219px;
      width: 275px;
    }
  }
}
