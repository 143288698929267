@import "styles/styles.scss";
.productPrice {
  @include bodyCopyRegular;

  &.productPrice_small {
    @include bodyCopySmallX;
  }

  &.productPrice_mediumSmall {
    @include bodyCopySmall;
  }

  &.productPrice_mediumBold {
    @include bodyCopyBold;
  }

  &.theme_light {
    color: $color-neutral-min;
  }

  &.theme_dark {
    color: $color-primary;
  }

  &.reverse {
    order: 1;
    margin-right: 12px;
  }

  .productPrice__supportText {
    @include bodyCopySmall;

    color: $color-terciary-light;
    display: inline-block;
    margin-right: 5px;
  }
}
