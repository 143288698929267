@import "styles/styles.scss";
.productOverview {
  width: 100%;

  .productOverview__image {
    height: 129px;
    width: 82px;
    display: block;
  }

  .productOverview__wrapper {
    background: $color-neutral-light;
    display: flex;
    flex: 0 0 auto;
    width: 100%;
  }

  .productOverview__content {
    padding: 20px;
    display: flex;
    flex: 1;
  }

  .productOverview__copy {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .productOverview__details {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    justify-content: space-between;

    a {
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  .productOverview__line {
    @include subtitleSmall;

    color: $color-terciary;
    margin-top: 5px;

    @include tablet-up {
      margin-top: 3px;
    }

    span:not(.letter-space) {
      display: inline-block;
    }
  }

  .productOverview__footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .productOverview__price {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    text-align: left;
  }

  .productOverview__badge {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .productOverview__title {
    @include subtitleSmall(true);

    word-break: break-word;

    .productOverview__line {
      margin-top: 2px;
    }
  }
}
