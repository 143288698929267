@import "styles/styles.scss";

.IconCard {
  align-items: center;
  background-color: $color-neutral-light;
  width: 300px;
  text-align: center;
  flex: 0 0 auto;
  padding: 60px 30px;
  display: flex;
  flex-direction: column;

  &:last-of-type {
    margin-right: 24px;

    @include desktop-up {
      margin-right: 0;
    }
  }

  @include tablet-up {
    width: 350px;
  }

  @include desktop-up {
    width: max-content;
    padding: 80px 40px;
    flex: 1;
  }
  
  img {
    display: block;
  }

  p {
    margin-bottom: 35px;
    max-width: 340px;
  }

  &__contentButton, &__contentLink {
    margin-top: auto;
  }

  &__contentButton {
    margin-bottom: -17px;
  }

  &__image {
    height: 50px;
    margin-bottom: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    @include titleSmallX2;

    margin-bottom: 30px;
  }
}