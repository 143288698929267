@import "styles/styles.scss";
.content {
  align-items: center;
  background-color: $color-neutral-min;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 1050px;
  margin: 0 auto;
  padding: 0 30px;

  @include desktopX2-up {
    padding-top: 100px;
  }

  @include tablet-up {
    min-height: 600px;
  }

  .content__title {
    text-align: center;
    margin-bottom: 5px;
  }

  .content__byline {
    @include bodyCopyRegular;

    text-align: center;
    margin: 7px auto 0;
    padding: 0 14px 30px;

    @include tablet-up {
      max-width: 500px;
      margin-top: 10px;
    }
  }

  .content__fitFinderClock {
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 20px;

    img {
      height: 140px;

      @include tablet-up {
        margin-top: 35px;
        height: 170px;
      }
    }
  }
}

.secondaryButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

div.SavedFitLandingPage__container {
  background-color: $color-neutral-min;
  padding: 0 30px 30px;
  min-height: 100%;
  height: fit-content;
}

.SavedFitLandingPage__buttons {
  width: 100%;
  margin-top: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin-bottom: 20px;
  }
}

.Presentation {
  margin-top: 40px;
}

.spacer {
  margin-top: 20px;
}
