@import "styles/styles.scss";
.Hero {
  overflow: hidden;
  position: relative;
  padding: 20px 24px 0;

  @include tablet-up {
    padding: 40px 60px 0;
  }

  @include desktop-up {
    padding: 60px 80px 10px;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-width: $breakpoint-desktopX1;
    margin: 0 auto;

    @include desktop-up {
      flex-direction: row-reverse;
    }
  }

  &__title {
    @include titleLargeX1;

    display: flex;
    text-wrap: nowrap;
    position: absolute;
    top: 110px;
    left: -8px;
    z-index: 1;

    @include tablet-up {
      @include titleLargeX4;

      top: 100px;
      left: -25px;
    }

    @include desktop-up {
      font-size: 6vw;
      top: 130px;
      left: -20px;
    }

    @include desktopX1-up {
      top: 28%;
    }

    @include desktopX3-up {
      top: 25%;
    }

    span {
      color: $color-secondary;
      display: block;

      @include desktop-up {
        display: inline;
      }
    }

    p {
      width: max-content;
      white-space: pre-wrap;

      @include tablet-up {
        max-width: 100%;
      }

      @include desktop-up {
        display: flex;
        flex-wrap: nowrap;
      }
    }
  }

  &__copy {
    align-self: flex-end;

    @include desktop-up {
      max-width: 431px;
    }

    p {
      margin-bottom: 10px;
    }
  }

  &__image {
    align-self: flex-end;
    max-width: 350px;
    height: auto;
    margin: 30px auto 40px;

    @include tablet-up {
      max-width: 400px;
      margin: 0 0 30px;
    }

    @include desktop-up {
      margin: 0;
      max-width: 450px;
    }

    @include desktopX1-up {
      margin-right: 5%;
      max-width: 500px;
    }
  }
}
