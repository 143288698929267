@import "styles/styles.scss";
.sectionHeadline {
  position: relative;
  margin: 0 auto 30px;

  @include desktop-up {
    margin-bottom: 50px;
  }

  .title {
    @include titleSmall;

    @include desktop-up {
      @include titleLargeX2;
    }

    text-align: center;
  }

  &.default {
    text-align: center;
  }

  &.animated {
    max-width: 100%;
    overflow: hidden;
    height: 100px;

    .title {
      @include titleMedium;
  
      display: flex;
      text-wrap: nowrap;
      position: absolute;
      left: 0;
      z-index: 1;
      bottom: 0;
  
      @include tablet-up {
        @include titleLargeX4;
  
        left: -8px;
        width: unset;
      }
  
      @include desktop-up {
        font-size: 6vw;
      }
  
      span {
        width: max-content;
        white-space: pre-wrap;
        
  
        @include desktop-up {
          display: flex;
          flex-wrap: nowrap;
          max-width: 100vw;
        }
      }
    }
  }

  &__withBorder {
    position: relative;
    height: auto;

    > * {
      padding-bottom: 30px;
      margin-bottom: 0;

      @include desktop-up {
        padding-bottom: 40px;
      }
    }

    &:is(.animated) {
      height: 70px;
  
      @include tablet-up {
        height: 135px;
      }

      @include desktopX2-up {
        height: 150px;
      }
    }

    &::after {
      bottom: 0;
      background-color: $color-secondary;
      content: "";
      display: block;
      height: 3px;
      position: absolute;
      left: 50%;
      width: 60px;
      transform: translateX(-50%);

      @include desktop-up {
        height: 5px;
        width: 100px;
      }
    }
  }
}