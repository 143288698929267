@import "styles/styles.scss";
@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.statement {
  text-align: center;
  background-color: $color-neutral-min;

  .statement__container {
    width: 100%;
    margin: auto;
  }

  &.statement__grey {
    background-color: $color-neutral-light;
  }

  &.statement_extraPadding {
    padding: 50px 0 65px;

    @include tablet-up {
      padding: 76px 0 63px;
    }

    @include desktop-up {
      padding: 90px 0;
    }
  }

  .statement__subtitle {
    color: inherit;

    @include subtitleLarge(true);

    margin-bottom: 8px;
    max-width: 520px;

    @include tablet-up {
      max-width: 100%;
    }
  }

  .statement__title {
    @include titleMedium(true);

    @include desktop-up {
      @include titleLargeX1;
    }

    padding-top: 73px;
    max-width: 520px;

    @include tablet-up {
      max-width: 100%;
    }
  }

  .statement__copy {
    max-width: 520px;
    display: inline-block;

    @include bodyCopyLargeX2;

    @include desktop-up {
      max-width: 100%;
    }
  }

  .statement__icon {
    padding-bottom: 32px;
  }

  .statement__byline {
    padding-bottom: 28px;
    text-align: center;
  }

  .statement__buttons__container {
    padding: 0 40px 60px;

    @include tablet-up {
      padding: 0 40px 110px;
    }
  }

  .statement__media {
    margin: auto;
    padding: 25px 0 0;
    opacity: 0;
    animation: fade-in 0.3s ease-in forwards;
    width: 100%;

    @include tablet-up {
      padding: 25px 0;
    }

    @include tablet-up {
      width: 90%;
    }
  }

  .statement__button {
    padding-bottom: 60px;
    margin: auto;
    padding-top: 11px;
    width: 200px;
  }

  .statement__button__item {
    margin: 10px 0;
    width: 100%;

    @include tablet-up {
      width: auto;
      margin: 0 10px;
    }
  }

  &.statement_staticHeader {
    padding: 50px 20px;

    @include tablet-up {
      padding: 91px 0;
    }

    .statement {
      &__title {
        padding: 0;
      }
    }
  }
}
