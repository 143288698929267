@import "styles/styles.scss";
.ImageCopySectionContainer__gallery {
  position: relative;
  backface-visibility: hidden;
  display: flex;
  overflow: hidden;
}

.ImageCopySectionContainer__image {
  width: 100%;
  overflow: hidden;
  

  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;

    @include tablet-up {
      height: 500px;
    }

    @include desktop-up {
      height: auto;
      object-fit: inherit;
    }
  }

  @include desktop-up {
    align-self: flex-start;
    width: 100%;
  }
}