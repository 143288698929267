@import "styles/styles.scss";
.oldFashioned {
  margin-bottom: 80px;

  .oldFashioned__list {
    margin-top: 50px;
    width: 100%;
    padding-left: 14px;
    padding-right: 14px;
  }

  .oldFashioned__list__container {
    @extend %list-no-style;

    margin: 0 auto;

    @media (max-width: 849px) {
      flex-direction: column;
    }
  }

  .oldFashioned__item {
    background-color: $color-neutral-min;
    border: 1px solid $color-neutral-medium;
    margin-top: 16px;
    position: relative;

    @media (min-width: 850px) {
      margin: 0 16px;
    }
  }

  .oldFashioned__img {
    background-color: $color-neutral-light;
    padding: 20px 0;
    width: 100%;
    display: flex;

    svg {
      max-width: 438px;
      width: 100%;
      margin: 0 auto;
    }

    @media (max-width: 849px) {
      display: none;
    }
  }

  .oldFashioned__icon {
    @extend %center;

    align-items: center;
    display: flex;
    background-color: $color-neutral-min;
    border-radius: 50%;
    box-shadow: 7px 13px 13px rgb(0 0 0 / 3%);
    height: 82px;
    justify-content: center;
    margin-top: 30px;
    width: 82px;

    @media (min-width: 850px) {
      display: none;
    }
  }

  .oldFashioned__item__content {
    @extend %center;

    max-width: 280px;
    padding: 27px 14px 63px;
    text-align: center;
  }

  .oldFashioned__item__title {
    @include subtitleMedium(true);

    color: $color-primary;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .oldFashioned__item__number {
    color: $color-primary;
  }

  .oldFashioned__item__copy {
    @include bodyCopyRegular;

    margin-top: 12px;
  }

  .oldFashioned__item__byline {
    @include bodyCopyLarge;
  }

  .oldFashioned__item__button {
    margin-top: 43px;
    display: flex;
    justify-content: center;
  }

  &.oldFashioned__item_fitFinder {
    padding-top: 34px;

    @include tablet-up {
      padding-top: 43px;
      padding-bottom: 62px;
    }

    .oldFashioned__list__container {
      margin: 0;
    }

    .oldFashioned__item {
      margin: 0;

      &:not(:first-child) {
        @media (max-width: 849px) {
          margin-top: 16px;
        }
      }

      &:nth-child(1) {
        @media (min-width: 850px) {
          margin-right: 14px;
        }
      }

      &:nth-child(2) {
        @media (min-width: 850px) {
          margin-left: 6px;
          margin-right: 7px;
        }
      }

      &:nth-child(3) {
        @media (min-width: 850px) {
          margin-left: 13px;
        }
      }

      &:nth-child(1),
      &:nth-child(2) {
        &::after {
          @media (min-width: 850px) {
            background-color: $color-neutral-min;
            border-bottom: 1px solid $color-neutral-medium;
            border-top: 1px solid $color-neutral-medium;
            content: "";
            height: 10px;
            left: 100%;
            position: absolute;
            top: 50%;
            width: 22px;
            z-index: 1;
          }
        }
      }
    }
  }
}

.tab__content {
  text-align: center;
}
