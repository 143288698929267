@import "styles/styles.scss";
.productsBreakdownGrid {
  display: flex;
  max-height: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  transition: all 0.3s ease-in-out;

  &.productsBreakdownGridActive {
    margin-bottom: 50px;
    max-height: 1000px;
    transition: all 0.3s ease-in-out;

    @media all and (max-width: 1330px) {
      overflow-x: auto;
    }
  }

  &.productsBreakdownGridWithBadge.productsBreakdownGridActive {
    padding-top: 33px;
  }

  .productsBreakdownGridList {
    @extend %list-no-style;

    display: flex;
    margin: 0 auto;
  }
}

.productsBreakdown__item {
  .productsBreakdown__itemWrapper {
    align-items: stretch;
    position: relative;
    background-color: $color-neutral-min;
  }
}

.spacer {
  transition: transform 0.3s $ease-in-out;
  flex: 0 0 8.5px;
  width: 8.5px;

  &:first-child,
  &:last-child {
    width: 20px;
    flex-basis: 20px;

    @include tablet-up {
      width: 40px;
      flex-basis: 40px;
    }

    @media all and (min-width: 1330px) {
      display: none;
    }
  }
}
