@import "styles/styles.scss";
.IconCards {
  background-color: $color-neutral-min;
 
  &__wrapper {
    max-width: $max-width;
    margin: 0 auto;
    padding: 0 0 50px;
    overflow: hidden;
  
    @include desktop-up {
      padding-bottom: 80px;
    }
  }

  &__title {
    margin: 0 24px;

    @include desktop-up {
      margin: 0 40px;
    }

    @include desktopX1-up {
      margin: 0;
    }
  }

  &__cards {
    overflow-y: auto;
    display: flex;
    gap: 10px;
    justify-items: flex-start;
    padding-left: 24px;

    @include desktop-up {
      padding: 0 40px;
    }

    @include desktopX1-up {
      padding: 0;
    }
  }
}