@import "styles/styles.scss";
.ButtonGroup {
  display: flex;

  &.fullWidth {
    button {
      flex: 1;
    }
  }

  &.column {
    flex-direction: column;

    button {
      &:not(:first-child) {
        border-top: none;
      }
    }
  }

  &.row {
    flex-direction: row;
    justify-content: center;

    /* stylelint-disable-next-line no-descending-specificity */
    button {
      &:not(:first-child) {
        border-left: none;
      }
    }
  }
}

.Button {
  @include bodyCopyRegular(true);

  color: $color-primary;
  border-color: $color-neutral-medium;
  background-color: $color-neutral-min;

  &:focus-visible,
  &:hover {
    background-color: $color-neutral-light;
    color: $color-primary;
  }

  @media (hover: none) and (pointer: coarse) {
    &:hover {
      color: $color-primary;
      border-color: $color-neutral-medium;
    }
  }

  &.selected {
    border-color: $color-primary;
    background-color: $color-primary;
    color: $color-neutral-min;

    &:focus-visible,
    &:hover {
      background-color: $color-primary-light;
      border-color: $color-primary-light;
    }

    @media (hover: none) and (pointer: coarse) {
      &:hover {
        background-color: $color-primary;
        border-color: $color-primary;
      }
    }
  }
}
