@import "styles/styles.scss";
.contentBenefits__media {
  margin-bottom: 40px;

  @include tablet-large-up {
    margin-bottom: 0;
    width: 50%;
  }
}

.contentBenefits__media_reverseOrder {
  order: 2;
}

.contentBenefits__containerDetails {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 30px 10px;
  text-align: center;

  @include tablet-large-up {
    justify-content: flex-start;
    text-align: left;
    width: 50%;
    padding: 0;
  }
}

.contentBenefits__content {
  @include tablet-large-down {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  @include tablet-large-up {
    margin-left: 80px;
    max-width: 310px;
  }
}

.contentBenefits__containerDetailsLeft {
  @include tablet-large-down {
    order: 2;
  }

  @include tablet-large-up {
    justify-content: flex-end;
  }

  .contentBenefits__content {
    @include tablet-large-up {
      margin: 0 80px 0 0;
    }
  }
}

.contentBenefits__title {
  span {
    color: $color-secondary;
    counter-increment: formula;
    margin-right: 10px;
  }
}

.contentBenefits__byline {
  color: $color-terciary;
  margin-top: 18px;
}

.contentBenefits__button {
  display: inline-block;
  margin-top: 20px;
  width: 100%;

  @include tablet-up {
    margin-top: 35px;
    width: auto;
  }
}

.contentBenefits {
  margin: 0 auto 50px;
  display: flex;
  width: 100%;
  flex-direction: column;

  @include tablet-large-down {
    max-width: 450px;
  }

  @include tablet-up {
    margin: 0 auto 70px;
  }

  @include tablet-large-up {
    justify-content: space-between;
    flex-direction: row;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.contentBenefits__markup {
    .contentBenefits__media {
      @include tablet-large-up {
        margin-left: 30px;
        width: 60%;
      }

      @include desktop-up {
        margin-left: 0;
      }
    }

    .contentBenefits__containerDetails {
      @include tablet-large-up {
        width: 40%;
        justify-content: flex-start;
      }
    }
  }
}
