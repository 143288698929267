@import "styles/styles.scss";
.ImageWrapper {
  background-color: $color-neutral-light;

  img {
    opacity: 0;
    transition: opacity 0.2s linear;
  }

  &.Image_ready img {
    opacity: 1;
  }
}
