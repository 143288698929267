@import "styles/styles.scss";

.FeatureCards__fullBleedContaier {
  color: var(--text-color);
  padding-bottom: 50px;

  @include tablet-up {
    display: grid;
    padding-bottom: 0;

    & > * {
      grid-column: 1;
      grid-row: 1;
    }
  }
}

.FeatureCards__wrapper {
  @include desktopX1-up {
    align-items: center;
  }
}

.FeatureCards__content {
  width: 100%;
  flex-shrink: 0;
  padding: 30px 30px 0;

  @include tablet-up {
    padding: 70px 50px 50px 0;
    width: 400px;
  }

  @include desktopX1-up {
    padding: 100px 70px 0;
    width: 100%;
    max-width: 720px;
  }

  @include tablet-pro-only-portrait {
    padding: 50px 70px 0 0;
    width: 500px;
  }

  @include tablet-only-landspace {
    padding: 50px 70px 0 0;
    width: 500px;
  }

  @include desktopX1-up {
    padding: 0 70px;
    height: 500px;
  }

  @include desktopX3-up {
    height: 600px;
  }
}

.FeatureCards__gallery {
  position: relative;
  backface-visibility: hidden;
  display: flex;
  overflow: hidden;
}

.FeatureCards__image {
  width: 100%;
  flex: 0 0 auto;
  overflow: hidden;
  position: relative;
  
  @include desktop-up {
    height: 0;
    @include placeholder-ratio(1440, 705);
  }

  @include tablet-pro-only-portrait {
    @include placeholder-ratio(1024, 600);
  }

  @include desktopX3-up {
    @include placeholder-ratio(2770, 1080);
  }
  

  img {
    display: block;
    object-fit: cover;
    object-position: left bottom;
    height: 100%;
    width: 100%;

    @include desktop-up {
      top: 0;
      left: 0;
      position: absolute;
    }
  }

  @include desktop-up {
    align-self: flex-start;
    width: 100%;
  }
}


