@import "styles/styles.scss";
.basketAddon {
  .basketAddon__title {
    @include subtitleSmall(true);
  }

  :global(.slick-dots) {
    margin-top: 10px;
    text-align: left;

    li {
      margin-right: 5px;
    }
  }
}

.basketAddon__productList {
  position: relative;
}

.basketAddon__carouselDots {
  position: absolute;
  top: 0;
  right: 20px;
  gap: 6px;
}

.basketAddon__titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 15px;
}
