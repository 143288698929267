@import "styles/styles.scss";
.ImageCopySectionCopy {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include tablet-up {
    height: 0;
    flex: 1 1 auto;
  }
}

.CopyGradient {
  @include tablet-up {
    margin-top: -60px;
    height: 60px;
    width: 100%;
    background: linear-gradient(to bottom, rgb(0 30 60 / 0%), rgb(0 30 60 / 100%));
  }

  @include desktopX3-up {
    display: none;
  }
}

.ImageCopySectionCopy__inlineSVG {
  margin: 60px auto 0;
  text-align: center;
 

  @include tablet-up {
    bottom: 20px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  @include desktopX1-up {
    bottom: 40px;
  }

  @include desktopX3-up {
    bottom: 80px;
  }
}


.ImageCopySectionCopy__wrapper {
  overflow-y: auto;
  scrollbar-color: $color-neutral-max transparent;
  scrollbar-width: thin;

  @include bodyCopySmall;

  @include tablet-pro-only-portrait {
    @include bodyCopySmall;
  }

  @include tablet-only-landspace {
    @include bodyCopySmall;
  }

  @include tablet-up {
    mask-image: linear-gradient(180deg, #000 80%, transparent);
  }

  @include desktop-up {
    @include bodyCopyRegular;
  }

  @include desktopX3-up {
    mask-image: none;
  }

  p {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;

      @include tablet-up {
        margin-bottom: 40px;
      }
    }
  }
}