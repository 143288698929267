@import "styles/styles.scss";
.DiscountCode {
  border-top: 1px solid $color-neutral-medium;
  padding: 20px 0;
  display: flex;
  flex-direction: column;

  .DiscountCode__line {
    display: flex;
    justify-content: space-between;

    .DiscountCode__title {
      flex-shrink: 1;

      .DiscountCode__text {
        display: flex;
        align-items: center;
        
        .DiscountCode__code {
          display: flex;
          margin-left: 10px;
          padding: 5px 9px;
          background: #F7f8f8;
          border-radius: 5px;
          font-size: 14px;
          color: #475161;
  
          .DiscountCode__svg {
            width: 19px;
            stroke: #475161;
            fill: transparent;
            margin-right: 7px;
          }
        }
      }
    }

    .DiscountCode__subtext {
      font-size: 14px;
    }
  
    .DiscountCode__price {
      flex-shrink: 0;
      color: $color-danger;
    }

    .DiscountCode__text,
    .DiscountCode__price {
      @include bodyCopyBold;
    }
  }
}