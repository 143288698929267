@import "styles/styles.scss";

.ImageCopySectionContainer {
  color: $color-neutral-min;


  &.copyPrimaryColor {
    color: $color-primary;

    .ImageCopySectionContainer__sectionTitle {
      h2 {
        color: $color-primary;
      }
    }

    .ImageCopySectionCopy__contentButton {
      border-color: $color-primary;
      color: $color-primary;
    }
  }

  @include tablet-up {
    display: grid;

    & > * {
      grid-column: 1;
      grid-row: 1;
    }
  }

  &.top-left, &.bottom-left {
    .ImageCopySectionContainer__content {
      order: -1;
    }

    .ImageCopySectionContainer__wrapper {
      @include desktopX3-up {
        grid-template-columns: 1000px 2fr;
      }
    }
  }

  &.top-right, &.bottom-right {
    .ImageCopySectionContainer__content {
      order: 1;
    }

    .ImageCopySectionContainer__wrapper {
      @include desktopX3-up {
        grid-template-columns: 2fr 1000px;
      }
    }
  }

  &.top-left, &.top-right {
    .ImageCopySectionContainer__contentContainer {
      align-self: flex-start;
    }
  }

  &.bottom-left, &.bottom-right {
    .ImageCopySectionContainer__contentContainer {
      align-self: flex-end;
    }
  }
}

.ImageCopySectionContainer__wrapper {
  @include tablet-up {
    display: grid;
    grid-template-columns: 1fr 1fr; 
  }

  @include desktopX1-up {
    align-items: center;
  }
}

.ImageCopySectionContainer__contentContainer {
  background-color: var(--background-color);
  padding: 40px 24px;
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;

  @include desktop-up {
    height: 75%;
    padding: 40px;
    width: 100%;
  }

  @include desktopX1-up {
    padding: 80px;
  }

  @include desktopX3-up {
    padding: 120px;
  }
}

.ImageCopySectionContainer__content {
  width: 100%;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  z-index: 1;
}

.ImageCopySectionContainer__sectionTitle {
  h2 {
    color: $color-neutral-min;
  }
}

.svgIcon {
  background-color: $color-neutral-min;
  display: block;
  mask-size: 100%;
  mask-repeat: no-repeat;
  mask-position: center;
  max-width: 100%;
  height: var(--svgIcon-height);
  width: var(--svgIcon-width);
  

  @include tablet-up {
    width: var(--svgIcon-width);
    height: var(--svgIcon-height);
    transform: scale(0.7);
  }

  @include desktopX1-up {
    transform: none;
  }
}

.ImageCopySectionCopy__contentButton {
  background-color: transparent;
  border-color: $color-neutral-min;
  color: $color-neutral-min;
  margin: 40px 0 0;

  @include desktop-up {
    align-self: flex-start;
  }
}

.titlePrimaryColor > * {
  color: $color-primary;
}