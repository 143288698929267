@import "styles/styles.scss";
.SectionHeader__button {
  margin: 25px 0;
  width: 100%;

  @include tablet-up {
    position: absolute;
    top: 40px;
    right: 0;
    margin: 0 30px 0 0;
    width: auto;
  }

  @include desktop-up {
    top: 64px;
    margin: 0;
  }
}
