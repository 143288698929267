@import "styles/styles.scss";
.giftMessageWrapper {
    @include bodyCopySmall;
  
    width: 100%;
    display: flex;
    justify-items: center;
    align-items: center;
    padding: 16px 0;
    border-top: 1px solid $color-neutral-medium;
    margin: 0;
  
    svg {
      margin-right: 5px;
      margin-top: -5px;
    }

    &__button {
      &[disabled] {
        cursor: not-allowed;
        pointer-events: none;
      }
    }
}

.giftMessageButtons {
  display: flex;
  margin-left: auto;

  button:first-of-type {
    padding-right: 10px;

    &::after {
      content: '|';
      background: transparent;
      margin-left: 10px;
      color: $color-terciary-light;
    }
  }
}