@import "styles/styles.scss";
.sizeChart__header {
  align-items: baseline;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 15px;

  @include tablet-up {
    justify-content: center;
    margin: 50px 0;
  }
}

.sizeChart__title {
  @include titleSmallX1;

  @include tablet-up {
    @include titleLarge;
  }

  span {
    @include titleSmallX2;
  }
}

.sizeChart__dropdown {
  position: relative;
  z-index: 2;
  top: -5px;

  &:not([data-open="true"]) {
    .sizeChart__nav {
      opacity: 0;
      transform: scale(0.5);
      transform-origin: top right;
      pointer-events: none;
    }
  }

  &[data-open="false"] {
    .sizeChart__trigger {
      &::after {
        transform: rotate(180deg);
      }
    }
  }
}

.sizeChart__button {
  @include titleSmallX2(true);

  border-style: none none dashed;
  border-bottom: 1px $color-terciary-light dashed;
  color: $color-secondary;
  margin-left: 10px;
  outline: none;
  padding-bottom: 2px;
  padding-right: 25px;
  position: relative;
  transition: border-bottom-color 0.3s $ease-in-out;

  @include tablet-up {
    padding-left: 10px;
    padding-right: 32px;
  }

  &::after {
    border-bottom: 5px solid transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $color-terciary-light;
    content: "";
    margin-top: -3px;
    position: absolute;
    right: 0;
    top: 50%;
    transition: border-top-color 0.3s $ease-in-out;
    transform-origin: center 2px;

    @include tablet-up {
      border-bottom: 7px solid transparent;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid $color-terciary-light;
    }
  }

  &:hover,
  &:focus {
    border-bottom-color: $color-secondary;
    background-color: transparent;

    &::after {
      border-top-color: $color-secondary;
    }
  }
}
