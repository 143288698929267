@import "styles/styles.scss";
.ImageRadio__wrapper {
  padding-left: 20px;

  &:last-child {
    padding-right: 20px;
  }
}

.ImageRadio {
  &:hover {
    cursor: pointer;
  }

  .ImageRadio__label {
    @include bodyCopyBold;

    color: $color-primary;
    display: inline-block;
    margin-bottom: 10px;

    &::after {
      content: '';
      background-color: $color-secondary;
      display: block;
      height: 1px;
      width: 100%;
    }
  }

  .ImageRadio__description {
    @include bodyCopySmall;
    @include bodyCopyLightBold;
  }

  .ImageRadio__input {
    display: none;
  }

  .ImageRadio__imageWrapper {
    padding: 15px;
    width: 276px;
    height: 276px;
    background-color: $color-neutral-min;
    border: 1px solid $color-neutral-medium;
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    svg {
      height: 100%;
    }

    .fakeRadioButton {
      position: absolute;
      bottom: 15px;
      left: 15px;
      height: 28px;
      width: 28px;
      cursor: pointer;
      background: $color-neutral-min;
      border: 1px solid $color-neutral-medium;
      border-radius: 100%;
      transition: border-color 0.3s $ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;

      &::after {
        border-radius: 100%;
        display: block;
        content: "";
        width: 10px;
        height: 10px;
        background-color: transparent;
        transition: background-color 0.3s $ease-in-out;
      }
    }
  }

  &.isSelected {
    .ImageRadio__imageWrapper {
      .fakeRadioButton {
        border-color: $color-primary;

        &::after {
          background-color: $color-primary;
        }
      }
    }
  }
}

.ImageCarousel__helper {
  @include bodyCopySmall();

  text-align: center;
  margin-top: 10px;
}