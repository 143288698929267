@import "styles/styles.scss";
.productsBreakdown {
  width: 100%;
}

.productsBreakdown__button__icon {
  display: block;
  height: 14px;
  position: relative;
  transform: translateY(50%);
  top: 0;
  margin-top: -14px;
  right: 0;
  width: 14px;

  &::before,
  &::after {
    content: "";
    background: $color-terciary-light;
    display: block;
    position: absolute;
    transform: rotate(0);
    transition: transform 0.3s ease-in-out;
  }

  &::before {
    height: 2px;
    width: 14px;
    top: 6px;
  }

  &::after {
    height: 14px;
    width: 2px;
    left: 6px;
  }
}

.productsBreakdown__button {
  background: $color-neutral-light;
  border-bottom: 1px solid $color-neutral-medium;
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 2px 20px 0;
  height: 60px;

  @include subtitleSmall;

  @include tablet-up {
    @include subtitleLargeX1;
  }

  &:focus,
  &:visited {
    outline: none;
  }

  &.productsBreakdown__button_active {
    border-color: transparent;
    outline: none;

    .productsBreakdown__button__icon {
      &::before {
        transition: transform 0.3s ease-in-out;
        transform: rotate(-45deg);
      }

      &::after {
        transition: transform 0.3s ease-in-out;
        transform: rotate(-45deg);
      }
    }
  }
}
