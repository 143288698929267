@import "styles/styles.scss";
.benefits {
  padding: 0 20px 50px;

  @include desktop-up {
    padding: 0 0 80px;
  }

  @include tablet-large-up {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.benefits__content {
  margin: 0 auto;
  max-width: 790px;

  @include desktop-up {
    max-width: 1000px;
  }
}
