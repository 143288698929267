@import "styles/styles.scss";
.AssetCallOut {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $max-width-large;

  @include desktop-up {
    justify-content: center;
    flex-direction: row;
    width: 100%;
    padding-left: 60px;
  }

  @media (min-width: #{$max-width-large}) {
    padding-left: 70px;
  }

  @include desktopX2-up {
    padding: 0;
  }

  &__title {
    @include titleMedium;

    margin-bottom: 30px;

    span {
      color: $color-secondary;
      padding-left: 2ch;
    }

    @include tablet-up {
      @include titleLargeX2;
    }
  }

  &__copy {
    @include tablet-up {
      @include bodyCopyLarge;
    }

    @include desktop-up {
      max-width: 495px;
    }
  }

  &__content {
    width: 100%;
    flex-shrink: 0;
    padding: 60px 24px 40px;

    @include tablet-up {
      padding-left: 60px;
      padding-right: 60px;
    }

    @include desktop-up {
      width: 520px;
      padding: 80px 0;
    }

    @include desktopX1-up {
      width: 640px;
    }

    @media (min-width: #{$max-width-large}) {
      width: 650px;
    }

    @include desktopX2-up {
      padding-left: 70px;
      width: 720px;
    }
  }

  &__image {
    flex: 1;
    width: 100%;
    display: block;

    @include desktop-up {
      width: 40%;
      height: auto;
      flex: 1;
      align-self: flex-start;
    }

    @include desktopX2-up {
      max-width: 720px;
    }
  }

  &__icon {
    padding: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $color-neutral-min;
    border: 1px solid transparent;
    margin-bottom: 10px;
    transition: border-color 0.2s $ease-in-out;

    @include mobile-up {
      width: 70px;
      height: 70px;
    }

    @include tablet-up {
      width: 95px;
      height: 95px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    gap: 20px;

    @include mobile-up {
      gap: 25px;
    }

    @include tablet-up {
      justify-content: flex-start;
      gap: 70px;
    }
  }

  &__button {
    @include bodyCopySmallX;

    position: relative;
    padding-bottom: 10px;
    margin-bottom: 30px;

    @include mobile-up {
      @include bodyCopySmall;
    }

    @include tablet-up {
      @include bodyCopyRegular;
    }

    &::after {
      position: absolute;
      bottom: 0;
      display: block;
      content: "";
      width: 100%;
      height: 3px;
      background-color: transparent;
      transition: background-color 0.2s $ease-in-out;
    }

    &.selected {
      .AssetCallOut__icon {
        border-color: $color-secondary;
      }

      &::after {
        background-color: $color-secondary;
      }
    }
  }
}
