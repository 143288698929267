@import "styles/styles.scss";
.cardMinimal {
  background-color: $color-neutral-medium;
  padding-bottom: 50px;
  position: relative;
  width: 285px;

  .cardMinimal_titleWrapper {
    align-items: center;
    bottom: 0;
    color: $color-neutral-min;
    display: flex;
    justify-content: center;
    height: 50px;
    padding: 0 20px;
    position: absolute;
    transition: height 0.1s ease-in-out;
    width: 100%;
  }

  &:hover {
    .cardMinimal_titleWrapper {
      height: 60px;
      transition: height 0.1s ease-in-out;
    }
  }

  .cardMinimal_title {
    @include subtitleMedium(true);

    color: $color-neutral-min;
  }
}

.cardMinimal_image {
  position: relative;
  background-color: $color-neutral-light;
  width: 100%;

  img {
    width: 100%;
    display: block;
  }

  :global(.lazyload-placeholder) {
    width: 290px;
  }
}
