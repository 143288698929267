@import "styles/styles.scss";
.cart__title {
  @include titleSmallX2;

  margin-top: 25px;
  text-align: center;

  @include tablet-up {
    margin-top: 35px;
  }

  @include desktop-up {
    margin-top: 40px;
  }
}

.cart__links {
  margin: 40px auto;
  padding: 0 40px;

  .cart__linkButton {
    margin-bottom: 20px;
  }
}
