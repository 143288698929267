@import "styles/styles.scss";
.FitResults__buttonWrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;

  button {
    margin-bottom: 20px;
  }
}

.SavedFitLandingPage__note {
  flex-direction: column;

  :first-child {
    @include bodyCopyBold;

    margin-bottom: 5px;
  }
}
