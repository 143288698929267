@import "styles/styles.scss";
.sizingWidget {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 80px;
  max-width: 100%;
  background: $color-neutral-min;

  .sizingWidget__container {
    margin: 0 auto;
    max-width: 375px;
    position: relative;
    width: 100%;
    padding: 0 14px;

    @include tablet-up {
      max-width: inherit;
    }
  }

  &:not(.sizingWidget__mobile__wrapper) {
    .sizingWidget__container {
      @include desktop-up {
        width: 100%;
        max-width: 980px;
      }
    }
  }

  .sizingWidget__wrapper {
    display: flex;

    @include desktop-up {
      width: 100%;
      max-width: 980px;
    }
  }
}

.sizingWidget__mobile__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $color-neutral-min;
  padding-bottom: 40px;

  @media (min-width: 500px) {
    padding-bottom: 0;
  }

  &.sizingWidget__mobile__wrapper_product {
    background: $color-neutral-min;
    margin: 0 auto;
    padding-bottom: 60px;
    width: calc(100% - 40px);
  }

  .sizingWidget__mobile {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    height: 570px;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    background: $color-neutral-min;
  }
}
