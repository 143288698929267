@import "styles/styles.scss";
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ProcessingLoader {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 10%;

  .ProcessingLoader__copy {
    @include titleSmallX2(true);

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    margin: 0;
    text-wrap: balance;
    max-width: 172px; /* Safari hack for text-wrap balance */
  }

  img {
    animation: rotate 7s linear infinite reverse;
  }
}
