@import "styles/styles.scss";
.productBreakdownImage {
  align-self: flex-start;
  background-color: $color-neutral-light;
  border-bottom: 1px solid $color-neutral-medium;
  height: 0;
  position: relative;
  @include placeholder-ratio(243, 217);

  margin-left: -1px;
  margin-right: -1px;
  overflow: hidden;

  .productBreakdownImage__image {
    width: 100%;
    height: auto;
    display: block;
  }

  .productBreakdownImage__link {
    display: block;
  }

  .productBreakdownImage__badge {
    position: absolute;
    left: 0;
    top: -30px;
    width: 100%;
  }
}
