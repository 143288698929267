@import "styles/styles.scss";
.sizeChart__nav {
  box-shadow: 0 2px 14px rgb(0 0 0 / 7%);
  background-color: $color-neutral-min;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  min-width: 200px;
  position: absolute;
  right: 0;
  top: 100%;
  transition: all 0.3s $ease-in-out;

  &[data-open="false"] {
    display: none;
  }
}

.sizeChart__button {
  @include subtitleDefaults();

  color: $color-primary;
  background-color: $color-neutral-min;
  height: 50px;
  border-left: 6px solid white;
  outline: none;
  padding-left: 15px;
  letter-spacing: normal;
  padding-right: 50px;
  transition: all 0.3s $ease-in-out;
  text-transform: capitalize;
  text-align: left;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
    border-left: 6px solid $color-neutral-light;
    background-color: $color-neutral-light;
  }

  &[data-selected="true"] {
    border-left-color: $color-secondary;
    color: $color-secondary;
    background-color: $color-neutral-light;
  }
}
