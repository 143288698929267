@import "styles/styles.scss";

.FeatureCards__content {
  width: 100%;
  flex-shrink: 0;
  padding: 30px 24px 0;

  @include tablet-up {
    padding: 30px;
    max-width: 420px;
  }

  @include desktop-up {
    padding: 80px 0;
    max-width: 450px;
    margin-left: 80px;
  }

  @include tablet-pro-only-portrait {
    padding: 30px 50px;
    max-width: 420px;
  }

  &_reverse {
    order: 1;

    @include desktop-up {
      margin-left: 0;
      margin-right: 60px;
      max-width: 485px;
    }
  }

  &_verticalCenter {
    @include tablet-up {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.FeatureCards__image {
  background-color: $color-neutral-light;
  flex: 1;
  display: block;
  width: calc(100% - 24px);
  overflow: hidden;

  @include desktop-up {
    height: 620px;
  }

  &_reverse {
    order: 2;
  }

  img {
    display: block;
    height: auto;
    width: 100%;
    
    @include desktop-up {
      height: 620px;
      width: auto;
    }

    @include desktopX2-up {
      height: 740px;
      margin-left: auto;
    }

    @include tablet-only-landspace {
      height: auto;
      width: 100%;
    }

    @include tablet-pro-only-portrait {
      height: auto;
      width: 100%;
    }
  }

  @include desktop-up {
    width: 100%;
    max-width: 100%;
    flex: 1;
    align-self: flex-start;
  }
}

.SectionHeaderWrapper {
  max-width: $max-width;
  padding: 0 24px;
  margin: auto;

  @include desktop-up {
    padding: 0 40px;
  }

  @include desktopX1-up {
    padding: 0 80px;
  }

  @include desktopX2-up {
    padding: 0;
  }
}