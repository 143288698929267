@import "styles/styles.scss";
$header-height: 50px;

.steps__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $header-height;
  background-color: $color-neutral-min;
  position: relative;

  .closeButton {
    margin-left: auto;
    width: 45px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .backButton {
    @include bodyCopySmall;
    @include bodyCopyLightBold;

    display: flex;
    align-items: center;
    margin-left: 20px;

    :first-child {
      margin-right: 5px;
    }

    &.hidden {
      visibility: hidden;
    }
  }

  .steps__headerTitle {
    @include bodyCopyLarge;

    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    transform: translateX(-50%);
    margin-left: 50%;
    color: $color-primary;

    &.hidden {
      visibility: hidden;
    }
  }

  button {
    cursor: pointer;
  }
}
