@import "styles/styles.scss";
.inputFields {
  display: flex;
  align-content: center;
  max-width: 225px;
  margin: 35px auto 20px;
  justify-content: space-between;
  width: 225px;

  label {
    flex: 1;
    position: relative;

    span {
      color: $color-terciary-light;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  label:nth-child(2) {
    margin-left: 14px;
  }
}

.inputFields__validationMessage {
  text-align: center;
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s $ease-in-out;
  max-width: 288px;
  width: 100%;
  margin: 0 auto 30px;

  @include tablet-up {
    margin: 0 auto 1em;
    max-width: 367px;
  }

  &.visible {
    opacity: 1;
  }

  p {
    @include bodyCopySmall;

    color: $color-terciary;

    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
    }
  }
}

.inputFields__unitToggler {
  display: flex;
  justify-content: center;

  button {
    padding: 0;

    @include bodyCopySmall;

    background: $color-neutral-min;
    color: $color-terciary;
    border: 1px solid $color-neutral-medium;
    cursor: pointer;
    height: 34px;
    width: 40px;
    outline: none;

    &[disabled] {
      cursor: not-allowed;
      background-color: $color-neutral-medium;
      color: $color-terciary-extra-light;
      border-color: $color-neutral-medium;
      pointer-events: none;
    }

    &:focus {
      border: 1px solid $color-terciary-extra-light;
    }
  }

  .button_active {
    color: $color-neutral-min;
    background-color: $color-primary;
    border-color: $color-primary;
  }

  .button_right {
    border-radius: 0 6px 6px 0;
    border-left: 0 solid $color-terciary-extra-light;

    &:focus {
      border-left-width: 0;
    }
  }

  .button_left {
    border-radius: 6px 0 0 6px;
    border-right-width: 0;

    &:focus {
      border-right-width: 0;
    }
  }
}