@import "styles/styles.scss";
.NewUser {
  margin-top: 40px;
}

.NewUser__title_emphasis {
  color: $color-secondary;
}

.spacer {
  margin-top: 20px;
}

.Links {
  padding-top: 40px;
  margin-top: 50px;
  border-top: 1px solid $color-neutral-medium;
  display: flex;

  &__card {
    &:first-child {
      margin-right: 4px;
    }

    &:last-child {
      margin-left: 4px;
    }
  }

  &__image {
    margin-top: 15px;
    margin-bottom: 5px;
    width: 100%;
    height: auto;
  }
}
