@import "styles/styles.scss";
$card-height: 190px;
$transition-delay: 0s;
$transition-easing: ease;
$transition-speed: 0.4s;

.sizingItemMobile {
  &.sizingItemMobile__card {
    align-items: center;
    background: no-repeat center center;
    background-size: cover;
    border-bottom: 1px solid $color-neutral-min;
    display: flex;
    flex-direction: column;
    height: $card-height;
    justify-content: center;
    max-width: 500px;
    overflow: hidden;
    opacity: 1;
    padding: 44px 30px 30px;
    position: absolute;
    transition: all $transition-speed $transition-easing $transition-delay;
    width: 100%;
    z-index: 4;

    &:last-child {
      border-bottom: none;
    }
  }

  .sizingItemMobile__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .sizingItemMobile__title {
    @include subtitleLarge(true);

    color: $color-neutral-min;
    text-shadow: 0 0 24px rgb(177 169 168 / 80%);
  }

  .sizingItemMobile__icon {
    margin-top: 12px;
  }

  &:global(.sizing-item-mobile-card-0) {
    transition: all $transition-speed $transition-easing 0;
    top: 0;
  }

  &:global(.sizing-item-mobile-card-1) {
    transition: all $transition-speed $transition-easing 0;
    top: $card-height;
  }

  &:global(.sizing-item-mobile-card-2) {
    border-bottom: none;
    transition: all $transition-speed $transition-easing 0;
    top: calc(#{$card-height} * 2);
  }

  &:global(.sizing-item-mobile-card.sizing-item-mobile-card-0.waist-moves-up) {
    top: -$card-height;
  }

  &:global(.sizing-item-mobile-card.sizing-item-mobile-card-1.waist-moves-up) {
    top: 100%;
  }

  &:global(.sizing-item-mobile-card.sizing-item-mobile-card-2.waist-moves-up) {
    top: calc(100% + #{$card-height});
  }

  &:global(
      .sizing-item-mobile-card.sizing-item-mobile-card-0.waist-and-build-moves-up
    ) {
    top: -380px;
  }

  &:global(
      .sizing-item-mobile-card.sizing-item-mobile-card-1.waist-and-build-moves-up
    ) {
    top: -$card-height;
  }

  &:global(
      .sizing-item-mobile-card.sizing-item-mobile-card-2.waist-and-build-moves-up
    ) {
    top: 100%;
  }
}
