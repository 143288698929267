@import "styles/styles.scss";
.form__input {
  @include subtitleLarge(true);

  height: $input-height-large;
  appearance: none;
  background-color: $color-neutral-min;
  border: 1px solid $color-neutral-medium;
  border-radius: 0;
  color: $color-terciary;
  outline: none;
  padding: 0 40px 0 18px;
  text-transform: none;
  transition: border-color 0.3s $ease-in-out;
  width: 100%;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  &:focus {
    border-color: $color-terciary;
  }

  &::placeholder {
    color: $color-terciary-extra-light;
    line-height: 40px;
    padding: 5px;
  }

  &:global(.form__input--error) {
    border-color: $color-danger;
    color: $color-primary !important;
  }

  &.form__input_large {
    font-size: 16px;
    height: 50px;
    margin-top: 12px;
  }

  &.form__input_reducedWidth {
    width: unset;
  }
}
