@import "styles/styles.scss";
$desktop-products-spacing: 60px;
$mobile-products-spacing: 24px;

.ProductsBreakdown {
  width: 100%;
  max-width: $max-width;
  margin: 0 auto;
  padding: 0 0 50px;

  @include desktop-up {
    padding: 0 0 80px;
  }

  &__header {
    padding: 0 $mobile-products-spacing;

    @include tablet-up {
      padding: 0 $desktop-products-spacing;
    }

    @include desktopX1-up {
      padding: 0;
    }
  }

  &__products {
    overflow-x: auto;
    display: flex;
    width: 100%;
    gap: 4px;
    
    @extend %list-no-style;

    li {
      width: 309px;
      height: 438px;
      position: relative;

      img {
        display: block;
        transition: transform 0.3s cubic-bezier(.4,0,.2,1);
      }

      &:first-child {
        margin-left: $mobile-products-spacing;
      }

      &:last-child {
        margin-right: $mobile-products-spacing;
      }

      @include tablet-up {
        &:first-child {
          margin-left: $desktop-products-spacing;
        }

        &:last-child {
          margin-right: $desktop-products-spacing;
        }
      }

      @include desktopX1-up {
        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__CardTitle {
    @include titleSmallX2;

    position: absolute;
    top: 16px;
    left: 12px;
    z-index: 1;
    margin: 0;
    writing-mode: tb-rl;
    transform: rotate(-180deg);
  }

  &__CardText {
    position: absolute;
    bottom: 16px;
    left: 16px;
    z-index: 1;
    width: max-content;
  }

  &__Card {
    text-decoration: none;
    position: relative;
    display: block;
    overflow: hidden;

    &__badge {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 1;
    }

    &:hover {
      img {
        transform: scale(1.03);
        transition: transform 0.6s cubic-bezier(.4,0,.2,1);
      }
    }

    &.theme_light {
      h3,
      p {
        color: $color-neutral-min;
      }
    }

    &.theme_dark {
      h3,
      p {
        color: $color-primary;
      }
    }
  }
}
