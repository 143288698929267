@import "styles/styles.scss";
.PageHeader {
  margin: 45px auto 15px;
  padding-left: 18px;
  padding-right: 18px;

  @include tablet-up {
    margin: 60px auto 50px;
    padding-left: 40px;
    padding-right: 40px;
    max-width: $max-width-small;
  }

  @include desktopX1-up {
    padding: 0;
  }
}