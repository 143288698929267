@import "styles/styles.scss";
%icon-mobile {
  width: 65px;
  height: 65px;
}

.FeatureCards__icon {
  padding: 6px;
  border-radius: 50%;
  background-color: $color-neutral-min;
  border: 2px solid transparent;
  margin-bottom: 5px;
  width: 79px;
  height: 79px;
  transition: border-color 0.2s $ease-in-out;

  @include tablet-pro-only-portrait {
    width: 79px;
    height: 79px;
  }

  @include tablet-only-landspace {
    width: 79px;
    height: 79px;
  }

  @include desktopX1-up {
    width: 95px;
    height: 95px;
  }
}

.FeatureCards__iconMinimal {
  padding: 0;
  width: 63px;
  height: 63px;

  @include desktopX1-up {
    width: 95px;
    height: 95px;
  }
}

.minimal {
  background-color: transparent;
}

.FeatureCards__buttons {
  $mobileGap: 15px;

  display: flex;
  align-items: flex-start;
  gap: $mobileGap;

  @include tablet-pro-only-portrait {
    gap: $mobileGap;
  }

  @include tablet-only-landspace {
    gap: $mobileGap;
  }

  @include desktopX1-up {
    justify-content: flex-start;
    gap: 20px;
    margin-left: -15px;
  }
}

.FeatureCards__button {
  $mobileWidth: 107px;
  @include bodyCopySmallX;

  position: relative;
  text-align: center;
  // width: $mobileWidth;
  margin-bottom: 40px;

  @include tablet-pro-only-portrait {
    // width: $mobileWidth;
    margin-bottom: 50px;
  }
  

  @include tablet-only-landspace {
    // width: $mobileWidth;
    margin-bottom: 50px;
  }

  @include desktopX1-up {
    width: 130px;
    margin-bottom: 60px;
  }

  &[disabled] {
    pointer-events: none
  }

  p {
    @include bodyCopySmallX;

    transition: var(--text-transition);

    @include desktopX1-up {
      @include bodyCopySmall;

      max-width: inherit;
    }

    @include tablet-pro-only-portrait {
      @include bodyCopySmallX;
    }

    @include tablet-only-landspace {
      @include bodyCopySmallX;
    }
  }

  &.selected {
    .FeatureCards__icon {
      border-color: $color-secondary;
      transition: border-color 0.2s $ease-in-out;
    }
  }
}

.FeatureCards__buttonMinimal {
  width: auto;

  @include desktopX1-up {
    margin-left: 15px;
  }
  
  img {
    margin-bottom: 0;
  }
}