@import "styles/styles.scss";
$desktop-products-spacing: 30px;
$mobile-products-spacing: 20px;

.ProductsPromo {
  padding-bottom: 30px;
  background-color: $color-neutral-min;
  border-top: 1px solid $color-neutral-medium;
  border-bottom: 1px solid $color-neutral-medium;
}

.ProductsPromo__container {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  max-width: $max-width + ($mobile-products-spacing * 2);
  width: 100%;

  @include tablet-up {
    max-width: $max-width + ($desktop-products-spacing * 2);
  }
}

.ProductsPromo__sectionHeader {
  padding-left: $mobile-products-spacing;

  @include tablet-up {
    padding-left: $desktop-products-spacing;
  }
}

.ProductsPromo__button {
  margin: 15px $mobile-products-spacing;

  @include tablet-up {
    margin: 15px auto;
  }
}
